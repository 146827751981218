import React, { FC, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { DoctorUrls, GlobalUrls, PatientUrls } from "../../lib/routes";
import { hideChat } from "../../lib/utils/hideChat";
import { Button } from "../../UI/Button";
import styles from "./index.module.css";

export const NotFound: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDoctorUrl = Object.values(DoctorUrls).includes(location.pathname as DoctorUrls);
  const isPatientUrl = Object.values(PatientUrls).includes(location.pathname as PatientUrls);

  useEffect(() => {
    hideChat();
  }, []);

  if (isDoctorUrl || isPatientUrl) {
    return <Navigate to={GlobalUrls.LOGIN} replace />;
  } else
    return (
      <div className={styles.notFound}>
        <h1>404</h1>
        <p>
          К сожалению, страница не найдена. <br />
          Вы можете вернуться на Главную.
        </p>
        <Button variant={"primary"} size={"large"} type={"button"} onClick={() => navigate("/")}>
          Вернуться на главную
        </Button>
      </div>
    );
};
